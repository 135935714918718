import { createApp } from 'vue'
import App from './App.vue'

import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// import TUICallKit
import { TUICallKit } from '@tencentcloud/call-uikit-vue';

const query = new URLSearchParams(window.location.search);
const myuid = query.get('myuid');
const mynickname = query.get('mynickname');
const touid = query.get('touid');
const tonickname = query.get('tonickname');
const touserimg = query.get('touserimg');
const myuserimg = query.get('myuserimg');

const SDKAppID = 1600003390; // Your SDKAppID
const secretKey = 'db2deaca5ccedfd5f09949b2dd60d44b8e4943ddf3cc0dd39d5b82e5d3d89493'; //Your secretKey
// let userID = touid; // User ID

console.log(myuid);

let userID = ''; // User ID
if(touid){
	userID = touid;
}
if(myuid&&!touid){
	userID = myuid;
}



// init TUIKit
const TUIKit = TUICore.init({
	SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);
// TUIKit add TUICallKit
TUIKit.use(TUICallKit);

if(touid){
	// login TUIKit
	TUIKit.login({
		userID: userID || '',
		userSig: genTestUserSig({
			SDKAppID,
			secretKey,
			userID,
		}).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
	}).then(function(){
		TUIKit.updateMyProfile({
			nick:tonickname,
			avatar:touserimg
		}).then(function (imResponse) {
			console.log(imResponse.data); // 更新资料成功
			TUIKit.logout().then(function (imResponse) {
				console.log(imResponse.data + '登出成功'); // 登出成功
				if(myuid){
					userID = myuid;
				}
				
				TUIKit.login({
					userID: userID || '',
					userSig: genTestUserSig({
						SDKAppID,
						secretKey,
						userID,
					}).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
				}).then(function(){
					console.log('updateMyProfile' + mynickname,'updateMyProfile' + myuserimg);
					TUIKit.updateMyProfile({
						nick: mynickname,
						avatar:myuserimg
					}).then(function (imResponse) {
						console.log(imResponse.data); // 更新资料成功
					}).catch(function (imError) {
						console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
					});
				});
			}).catch(function (imError) {
				console.warn('logout error:', imError);
			});
		}).catch(function (imError) {
			console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
		})
	});
}else{
	console.log('可以');
	// login TUIKit
	TUIKit.login({
		userID: userID || '',
		userSig: genTestUserSig({
			SDKAppID,
			secretKey,
			userID,
		}).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
	}).then(function(){
		console.log('登录成功');
		TUIKit.updateMyProfile({
			nick:mynickname,
			avatar:myuserimg
		})
	});
}


createApp(App).use(TUIKit).mount('#app');